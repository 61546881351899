
export function Component() {
  
  const text = `Test Component!!!`

  return (
    <p>
      {text}
    </p>
  );
}
